import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import Project from '../components/Home/project';
import Footer from '../components/Layout/footer';
import SEOHeader from '../components/Head';

type Props = {
    data: {
        allMdx: {
            nodes: Array<{
                slug: string;
                frontmatter: {
                    title: string;
                };
            }>;
        };
        allImageSharp: {
            nodes: Array<{
                gatsbyImageData: IGatsbyImageData;
                parent: {
                    relativePath: string;
                };
            }>;
        };
    };
};

const Index: FC<Props> = ({
    data: {
        allMdx: { nodes: projects },
        allImageSharp: { nodes: images },
    },
}) => (
    <>
        <SEOHeader title="Pierre Buzulier - Work" />
        <main className="container mx-auto py-6 min-h-screen relative pb-24">
            <div className="mt-12 lg:mt-28 grid-col-1 lg:grid-cols-3 grid gap-10">
                {projects.map((project) => (
                    <Project
                        key={project.slug}
                        title={project.frontmatter.title}
                        slug={project.slug}
                        image={
                            images.find((image) => image.parent.relativePath === `projects/${project.slug}/0.png`)
                                ?.gatsbyImageData
                        }
                    />
                ))}
            </div>
        </main>
        <Footer />
    </>
);

export const query = graphql`
    query Projects {
        allMdx(sort: { fields: frontmatter___order, order: ASC }) {
            nodes {
                slug
                frontmatter {
                    title
                }
            }
        }
        allImageSharp {
            nodes {
                gatsbyImageData
                parent {
                    ... on File {
                        relativePath
                    }
                }
            }
        }
    }
`;

export default Index;
