import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

type Props = {
    title: string;
    slug: string;
    image: IGatsbyImageData | undefined;
};

const Project: React.FC<Props> = ({ title, slug, image }) => (
    <Link to={slug} className="relative group cursor-pointer">
        {image && <GatsbyImage alt={slug} image={image} />}
        <div className="absolute top-0 left-0 opacity-0 bg-black lg:group-hover:opacity-60 w-full h-full" />
        <div className="absolute top-1/2 left-1/2 opacity-0 text-white lg:group-hover:opacity-100 -translate-x-1/2 translate-y-1/2 lowercase text-2xl w-full text-center">
            {title}
        </div>
    </Link>
);

export default Project;
